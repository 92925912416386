import ImageListViewer from "../components/ImageListViewer";
import { Text, Card } from "react-native-paper";
import { Platform, Pressable, View } from "react-native";
import { openURL } from "expo-linking";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

export const cachedDataLists = {};
export const cachedCustomLists = {};

export default function decideField(field, data, customLists = {}, users = {}, theme) {
 if (!data[field.key]) return null;
 if (Array.isArray(data[field.key]) && data[field.key].length === 0) return null;

 if (field.type === "string" || field.type === "currency") {
  return (
   <Text variant="bodySmall" key={field.key}>
    <Text variant="labelMedium" style={{ fontWeight: "600" }}>
     {field.label}:
    </Text>{" "}
    {data[field.key]}
   </Text>
  );
 } else if (field.type === "phone") {
  return (
   <View
    key={field.key}
    style={{ marginVertical: 5, display: "flex", flexDirection: "row", alignItems: "center" }}>
    <Text variant="bodySmall" key={field.key}>
     <Text variant="labelMedium" style={{ fontWeight: "600" }}>
      {field.label}:
     </Text>{" "}
     {data[field.key]}
    </Text>
    <Pressable
     onPress={() => {
      if (Platform.OS === "web") return;
      let parsedNumber = data[field.key].replace(/\D/g, "");
      if (parsedNumber.length <= 11) parsedNumber = `+55${parsedNumber}`;
      const url = `tel:${parsedNumber}`;
      openURL(url);
     }}>
     <MaterialCommunityIcons
      style={{ marginLeft: 5 }}
      name="phone"
      size={20}
      color={theme.colors.primary}
     />
    </Pressable>
    <Pressable
     onPress={() => {
      let parsedNumber = data[field.key].replace(/\D/g, "");
      if (parsedNumber.length <= 11) parsedNumber = `55${parsedNumber}`;
      const url = `https://wa.me/${parsedNumber}`;
      if (Platform.OS === "web") window.open(url, "_blank");
      else openURL(url);
     }}>
     <MaterialCommunityIcons
      style={{ marginLeft: 5 }}
      name="whatsapp"
      size={20}
      color={theme.colors.primary}
     />
    </Pressable>
   </View>
  );
 } else if (field.type === "list") {
  const label = field.options?.find(({ value }) => value === data[field.key])?.label;
  return (
   <Text variant="bodySmall" key={field.key}>
    <Text variant="labelMedium" style={{ fontWeight: "600" }}>
     {field.label}:
    </Text>{" "}
    {label}
   </Text>
  );
 } else if (field.type === "address") {
  return (
   <View key={field.key} style={{ marginVertical: 5 }}>
    <Text variant="labelMedium" style={{ fontWeight: "600" }}>
     {field.label}:{" "}
    </Text>
    <View
     style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "85%",
      marginTop: 5,
     }}>
     <Pressable
      onPress={() => {
       let url = null;

       if (data[field.key]?.includes("https://")) url = data[field.key];
       else {
        let queue = data[field.key];
        if (data["cidade"] || data["city"]) queue += `, ${data["cidade"] || data["city"]}`;
        url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(queue.trim())}`;
       }
       
       if (Platform.OS === "web") window.open(url, "_blank");
       else openURL(url);
      }}>
      <MaterialCommunityIcons name="google-maps" size={20} color={theme.colors.primary} />
     </Pressable>
     <Pressable
      style={{ marginHorizontal: 5 }}
      onPress={() => {
       let queue = data[field.key];
       if (data["cidade"] || data["city"]) queue += `, ${data["cidade"] || data["city"]}`;
       const url = `https://waze.com/ul?q=${encodeURIComponent(queue.trim())}`;
       if (Platform.OS === "web") window.open(url, "_blank");
       else openURL(url);
      }}>
      <MaterialCommunityIcons name="waze" size={20} color={theme.colors.primary} />
     </Pressable>
     <Text variant="bodySmall">{data[field.key]}</Text>
    </View>
   </View>
  );
 } else if (field.type === "file") {
  if (!data[field.key]?.uri) return null;
  return (
   <View key={field.key} style={{ marginVertical: 5 }}>
    <Text variant="labelMedium" style={{ fontWeight: "600" }}>
     {field.label}:{" "}
    </Text>
    <Pressable
     onPress={() => {
      if (Platform.OS === "web") window.open(data[field.key].uri, "_blank");
      else openURL(data[field.key].uri);
     }}>
     <View
      style={{
       display: "flex",
       flexDirection: "row",
       alignItems: "center",
       justifyContent: "flex-start",
       padding: 5,
      }}>
      <MaterialCommunityIcons name="open-in-new" size={18} color={theme.colors.primary} />
      <Text style={{ marginLeft: 5, color: theme.colors.primary }}>{data[field.key].name}</Text>
     </View>
    </Pressable>
   </View>
  );
 } else if (field.type === "date")
  return (
   <Text variant="bodySmall" key={field.key}>
    <Text variant="labelMedium" style={{ fontWeight: "600" }}>
     {field.label}:
    </Text>{" "}
    {new Date(data[field.key]).toLocaleDateString()}
   </Text>
  );
 else if (field.type === "datetime")
  return (
   <Text variant="bodySmall" key={field.key}>
    <Text variant="labelMedium" style={{ fontWeight: "600" }}>
     {field.label}:
    </Text>{" "}
    {new Date(data[field.key]).toLocaleString()}
   </Text>
  );
 else if (field.type === "multitext") {
  return (
   <View key={field.key} style={{ marginVertical: 5 }}>
    <Text variant="labelMedium" style={{ fontWeight: "600" }}>
     {field.label}:
    </Text>
    <View style={{ marginLeft: 5, width: "100%" }}>
     <Text variant="bodySmall">{data[field.key].join("\n")}</Text>
    </View>
   </View>
  );
 } else if (field.type === "imageList") {
  return <ImageListViewer key={field.key} placeholder={field.label} value={data[field.key]} />;
 } else if (field.type === "dataList") {
  let mappedFields = [];
  if (cachedDataLists[field.key]) mappedFields = cachedDataLists[field.key];
  else {
   const fields = [];
   Object.keys(field.fields).forEach((key) => {
    const f = field.fields[key];
    f.key = key; // jogamos o o identificador do field pra dentro do objeto
    fields.push(f);
   });
   cachedDataLists[field.key] = fields;
   mappedFields = fields;
  }

  return data[field.key].map((data, index) => (
   <Card mode="elevated" style={{ marginBottom: 15 }} key={index}>
    <Card.Content>
     {mappedFields.map((field) => decideField(field, data, customLists, users))}
    </Card.Content>
   </Card>
  ));
 } else if (field.type === "user") {
  return (
   <Text variant="bodySmall" key={field.key}>
    <Text variant="labelMedium" style={{ fontWeight: "600" }}>
     {field.label}:
    </Text>{" "}
    {users[data[field.key]]?.email}
   </Text>
  );
 } else if (customLists[field.type]) {
  if (customLists[field.type].type === "amount")
   return (
    <View key={field.key} style={{ marginVertical: 5 }}>
     <Text variant="labelMedium" style={{ fontWeight: "600" }}>
      {field.label}:
     </Text>
     <View style={{ marginLeft: 5, width: "100%" }}>
      <Text variant="bodySmall">
       {data[field.key].map(({ name, amount }) => `${name} (${amount})`).join("\n")}
      </Text>
     </View>
    </View>
   );
  else {
   if (data[field.key] && typeof data[field.key] !== "string")
    data[field.key] = JSON.stringify(data[field.key]);
   return (
    <Text variant="bodySmall" key={field.key}>
     <Text variant="labelMedium" style={{ fontWeight: "600" }}>
      {field.label}:
     </Text>{" "}
     {data[field.key]}
    </Text>
   );
  }
 } else {
  return <Text key={field.key}>Campo Inexistente</Text>;
 }
}
